import React from "react";
import ReactDOM from "react-dom";
import uniqueId from "lodash/uniqueId";

import KTService from "kt_jsgem/lib/kt_service";
import KTWrapper from "kt_jsgem/lib/kt_wrapper";
import LanguageLevel from "kt_jsgem/lib/language_level";
import MenuManager from "kt_jsgem/kt_editor/menu/manager";
import SidePanelComponent from "kt_jsgem/kt_editor/side_panel/side_panel";
import TextElementWrapper from "kt_jsgem/kt_editor/text_element_wrapper";
import Protocols from "kt_jsgem/lib/protocols";

class Editor {
  constructor(initialConfig, textElement, sidebarElement, options = {
    ktWrapperImplementation: KTWrapper, textElementWrapperImplementation: TextElementWrapper
  }) {
    this.loading = true;
    this.onRender = () => {};
    this.onConfigLoaded = () => {};
    this.onReset = () => {};
    this.requestOptions = {
      languageLevel: LanguageLevel[initialConfig.sidepanel && initialConfig.sidepanel.defaultLanguageLevel || "C1"],
      language: initialConfig.sidepanel && initialConfig.sidepanel.language && initialConfig.sidepanel.language.selected
    };
    this.responseOptions = {
      annotations: [],
      languageLevel: undefined,
      checkType: undefined,
      lastCheckTimestamp: null
    };

    this.protocol = Protocols.protocolForConfig(initialConfig);
    this.configLoader = this.protocol.createConfigLoader(initialConfig);
    this.kt = new options.ktWrapperImplementation();
    this.setTextElement(textElement);
    this.setSidebarElement(sidebarElement);
    this.textElementWrapper = new options.textElementWrapperImplementation;
    this.menuManager = new MenuManager(window.document.body);
    this.textElementWrapper.setHandleMenuOpen(this.menuManager.handleToggleMenuClickEvent);
    this.textElementWrapper.setHandleMenuClose(this.menuManager.closeMenu);
    this.textElementWrapper.updateMenuElement(this.menuManager.menu.el);
    this.generateDocumentName();
    this.setFocusedAnnotation(null);
    this.notificationMessages = [];

    this.loadConfiguration();

    this.kt.on("done", (textElement, _options) => {
      this.setTextElement(textElement);
    });

    this.kt.on("parsed", (annotations, options, problemSizes, assignments) => {
      this.responseOptions.annotations = annotations;
      this.responseOptions.languageLevel = options.languageLevel;
      this.responseOptions.checkType = options.checkType;
      this.responseOptions.lastCheckTimestamp = new Date();
      this.problemSizes = problemSizes;
      this.responseOptions.assignments = assignments;
      this.render();
    });

    this.kt.on("change", (_textElement) => {
      for (const annotationType of Array.from(this.responseOptions.annotationTypes)) {
        annotationType.count = this.textElementWrapper.annotationsForCategoryCount(annotationType.id);
      }
      this.render();
      this.menuCallbacks.onBlurAnnotation();
    });

    this.kt.on("checking", () => {
      this.checking = true;
      this.render();
      this.menuCallbacks.onBlurAnnotation();
    });

    this.kt.on("done", () => {
      this.checking = false;
      this.render();
    });

    const replaceAnnotation = (ktid, alternative) => {
      this.menuManager.closeMenu();
      const annotation = this.responseOptions.annotations.find((a) => a.id === ktid);
      this.responseOptions.annotations = this.responseOptions.annotations.filter((a) => annotation !== a);
      this.kt.replaceAnnotation(this.textElementWrapper, annotation, alternative);

      const sameLengthAnnotations = this.responseOptions.annotations.filter((a) => a.start === annotation.start && a.end === annotation.end);
      if(sameLengthAnnotations.length) {
        this.responseOptions.annotations = this.responseOptions.annotations.filter((a) => !(a.start === annotation.start && a.end === annotation.end));

        sameLengthAnnotations.forEach((otherAnnotation) => {
          this.kt.replaceAnnotation(this.textElementWrapper, otherAnnotation, null);
        });
      }
    };

    this.menuCallbacks = {
      onReplaceAnnotation: replaceAnnotation,
      onIgnoreAnnotation: replaceAnnotation,
      onAddToPersonalList: (ktid, wordInText, word, mainCategory, typeId) => {
        replaceAnnotation(ktid, wordInText);
        return this.ktService.addSuggestion(word, [], {
          user: this.ktConfig.user,
          isAllowed: true,
          mainCategory,
          subCategory: typeId,
          wordInText
        });
      },
      onFocusAnnotation: (annotation) => {
        this.setFocusedAnnotation(annotation);
      },
      onBlurAnnotation: () => {
        this.setFocusedAnnotation(null);
      },
      onDisplayMessage: (type, notification) => {
        this.notificationMessages.push({ id: uniqueId(), type: type, notification: notification });
        this.render();
      },
      onDisplayMessageClose: (id) => {
        if (!id) { return; }
        this.notificationMessages = this.notificationMessages.filter((notification) => notification.id !== id);

        this.render();
      }
    };
  }

  setTextElement(textElement) {
    this.textElement = textElement;
    this.render();
  }

  setSidebarElement(sidebarElement) {
    this.sidebarElement = sidebarElement;
  }

  loadConfiguration(language) {
    this.loading = true;
    if (language == null) { language = null; }
    return this.configLoader.load(language).then((ktConfig) => {
      this.configurationLoaded(ktConfig);
    }).catch(() => {});
  }

  configurationLoaded(ktConfig) {
    const initial = this.ktConfig == null;
    this.ktConfig = ktConfig;
    if(initial) {
      this.responseOptions.annotationTypes = Array.from(this.ktConfig.annotationTypes).map((annotationType) => {
        let disabled = !annotationType.display.enabled;
        if(annotationType.display.defaultEnabled != null) {
          disabled = !annotationType.display.defaultEnabled;
        }

        return {
          id: annotationType.id,
          count: 0,
          disabled
        };
      });
    }
    const webserviceURL = this.ktConfig.webserviceURL;
    const suggestionURL = this.ktConfig.addSuggestionURL;

    this.ktService = new KTService({
      analyzePath: webserviceURL,
      addSuggestionPath: suggestionURL
    }, { protocol: this.protocol });
    if(initial) {
      this.onConfigLoaded();
    }
    this.loading = false;
    this.render();
  }

  render() {
    if (this.ktConfig && this.ktConfig.sidepanel) {
      this.renderSidepanel();
    }
    this.renderMenu();
    this.onRender();
  }

  renderSidepanel() {
    if (this.loading || !this.sidebarElement) { return; }
    const callbacks = {
      languageChanged: (selected) => {
        this.requestOptions.language = selected;
        this.loadConfiguration(selected);
        this.render();
      },
      annotationTypeClick: (annotationTypeId) => {
        const annotationType = this.ktConfig.annotationTypes.find((at) => at.id === annotationTypeId);

        if(annotationType.display.enabled) {
          const annotationTypeState = this.responseOptions.annotationTypes.find((at) => at.id === annotationTypeId);
          annotationTypeState.disabled = !annotationTypeState.disabled;
          this.toggleAnnotationType(annotationType, annotationTypeState);
        }
        this.render();
      },
      languageLevelChanged: (value) => {
        this.requestOptions.languageLevel = value;
        this.render();
      },
      checkTypeClicked: (value) => {
        this.requestOptions.checkType = value;
        this.clearAnnotations();
        this.performCheck();
      },
      performCheckClicked: () => {
        this.requestOptions.checkType = undefined;
        this.clearAnnotations();
        this.performCheck();
      },
      clearButtonClicked: () => {
        this.clearAnnotations();
      },
      newSuggestionSubmitted: (suggestion) => {
        const annotationType = this.ktConfig.annotationTypes.find((at) => at.id === suggestion.annotationTypeId);

        return this.ktService.addSuggestion(suggestion.word, suggestion.alternatives,
          {
            user: this.ktConfig.user,
            subCategory: suggestion.annotationTypeId,
            mainCategory: annotationType.checkType
          });
      },
      replaceAnnotation: this.menuCallbacks.onReplaceAnnotation,
      addToPersonalList: this.menuCallbacks.onAddToPersonalList,
      displayMessage: this.menuCallbacks.onDisplayMessage,
      onDisplayMessageClose: this.menuCallbacks.onDisplayMessageClose,
      onCopyText: () => {
        this.textElementWrapper.copyTextToClipboard();
      },
      loadDocumentFromLadClicked: () => {
        this.fetchDocumentFromLad();
      }
    };

    const props = {
      config: this.ktConfig,
      requestOptions: this.requestOptions,
      responseOptions: this.responseOptions,
      callbacks: callbacks,
      loading: this.loading,
      checking: this.checking,
      focusedAnnotation: this.focusedAnnotation,
      notificationMessages: this.notificationMessages,
      problemSizes: this.problemSizes
    };

    ReactDOM.render(React.createElement(SidePanelComponent,
      props), this.sidebarElement);
  }

  renderMenu() {
    if (this.loading || !this.textElement) { return; }

    this.textElementWrapper.update({
      response: this.responseOptions,
      config: this.ktConfig,
      textElement: this.textElement
    });
    this.menuManager.updateTextElement(this.textElement);
    this.textElementWrapper.installMenuCloseEventHandlers();
    this.menuManager.updateConfig(this.ktConfig);
    this.menuManager.updateCallbacks(this.menuCallbacks);
    this.menuManager.updateResponseAnnotationTypes(this.responseOptions.annotationTypes);
    this.menuManager.updateAnnotations(this.responseOptions.annotations);
  }

  setFocusedAnnotation(annotation) {
    const prevFocusedAnnotation = this.focusedAnnotation;
    this.focusedAnnotation = annotation;
    if(prevFocusedAnnotation !== annotation) {
      this.render();
    }
  }

  resetLanguageLevel() {
    this.responseOptions.languageLevel = undefined;
    this.responseOptions.checkType = undefined;
    this.render();
  }

  clearAnnotations() {
    this.responseOptions.annotations = [];
    if (!this.loading) { this.kt.clearAnnotations(this.textElementWrapper); }

    this.problemSizes = [];
    this.responseOptions.assignments = [];
  }

  performCheck() {
    return this.loadConfiguration(this.requestOptions.language).then(() => {
      this.notificationMessages = [];
      if(this.ktConfig.overLimit) {
        this.notificationMessages.push({
          id: uniqueId(),
          type: "info",
          notification: this.ktConfig.translations.overLimitNotification || "U bent over uw limiet, markeringen worden niet getoond"
        });
      }
      return this.kt.performCheck({
        service: this.ktService,
        textElement: this.textElement,
        languageLevel: this.requestOptions.languageLevel,
        checkType: this.requestOptions.checkType,
        user: this.ktConfig.user,
        documentName: this.ktConfig.documentName || this.documentName,
        annotationElementCreator: this.textElementWrapper.createAnnotationElement,
        annotationTypesState: this.responseOptions.annotationTypes,
        annotationTypes: this.ktConfig.annotationTypes,
        annotator: this.textElementWrapper.annotator(this.ktConfig.annotationTypes)
      });
    });
  }

  fetchDocumentFromLad() {
    this.kt.trigger("beforeCheck", this.textElement);
    this.kt.trigger("checking", this.textElement);

    this.ktService.transport.performRequest(this.ktConfig.fetchFromLADURL, "", { method: "GET" }).then((data) => {
      const response = this.ktService.protocol.parseXMLResponse(data);
      const annotations = response.categories.filter((annotation) => this.ktConfig.annotationTypes.find((at) => at.id === annotation.typeId));

      this.textElement.innerText = response.content;
      this.kt.trigger("parsed", annotations, { languageLevel: response.languageLevel }, response.problemSizes);

      const domAnnotator = this.textElementWrapper.annotator(this.ktConfig.annotationTypes);
      return domAnnotator.annotate(annotations);
    }).then(() => {
      this.kt.trigger("done", this.textElement, {});
      this.kt.trigger("change", this.textElement);
    });
  }

  reset() {
    this.clearAnnotations();
    this.resetLanguageLevel();
    this.generateDocumentName();
    this.onReset();
  }

  destroy() {
    ReactDOM.unmountComponentAtNode(this.sidebarElement);
    this.clearAnnotations();
  }

  generateDocumentName() {
    this.documentName = Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15);
  }

  toggleAnnotationType(_annotationType, _annotationTypeState) {
    // Noop
  }
}

export default Editor;
