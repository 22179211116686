import FetchTransport from "kt_jsgem/lib/transports/fetch_transport";

const Transports = {};
Transports.TRANSPORTS = [FetchTransport];

Transports.performRequest = function(url, xmlString, options, successCallback, errorCallback) {
  for (const transport of Transports.TRANSPORTS) {
    if (transport.shouldUse(url)) {
      return transport.performRequest(url, xmlString, options, successCallback, errorCallback);
    }
  }

  throw new Error("Could not find usable transport class!");
};

export default Transports;
