/* global Kt, KTEditor */

import "core-js/stable";
import "regenerator-runtime/runtime";

import "kt_jsgem/ckeditor";

import $ from "jquery";
import CKEDITOR from "ckeditor";

import React from "react";
import ReactDOM from "react-dom";

import "@fortawesome/fontawesome-pro/js/all";

import "./merktaal";

$(() => {
  const getCkeditor = (element) => CKEDITOR.instances[$(element).data("target")];

  $("button.reset").click(function() {
    const editor = getCkeditor(this);
    if (editor != null) {
      editor.ktPluginInstance.ktEditor.reset();
      editor.setData("");
    }
  });

  $("button.copy").on("click", (event) => {
    const ckEditor = getCkeditor(event.target);
    ckEditor.ktPluginInstance.ktEditor.textElementWrapper.copyTextToClipboard();
  });

  $("button.removeAnnotations").on("click", (event) => {
    getCkeditor(event.target).ktPluginInstance.ktEditor.clearAnnotations();
    getCkeditor(event.target).ktPluginInstance.ktEditor.render();
  });

  const ktUser = $(".kt-user");
  $(".kt-user").remove();
  if (ktUser[0]) {
    Kt.Protocols.Kt1Adapter.overrideUser = {
      username: ktUser.data("username"),
      password: ktUser.data("password"),
      originalUser: ktUser.data("originalUser") ? ktUser.data("originalUser") : undefined
    };
  }

  CKEDITOR.on("instanceReady", ({editor}) => {
    const element = $(`.lastCheck[data-target=${editor.name}]`)[0];

    const renderLastCheck = (timestamp) => {
      ReactDOM.render(
        React.createElement(KTEditor.LastCheckComponent, {
          translations: editor.config.extraConfig.KT.translations,
          lastCheckTimestamp: timestamp
        }), element
      );
    };

    editor.ktPluginInstance.ktEditor.kt.on("parsed", () => {
      renderLastCheck(new Date());
    });
    renderLastCheck(null);
  });
});
