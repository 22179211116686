import AnnotationElementWrapper from "kt_jsgem/kt_editor/menu/annotation_element_wrapper";

import DomAnnotator from "kt_jsgem/lib/dom_annotator";
import Fragment from "kt_jsgem/lib/fragment";
import Stylesheet from "kt_jsgem/kt_editor/stylesheet";

const removeAnnotationTag = (element) => {
  const parent = element.parentNode;

  Array.from(element.childNodes).forEach((childNode) => {
    parent.insertBefore(childNode, element);
  });
  parent.removeChild(element);
};

class TextElementWrapper {
  constructor() {
    this.createAnnotationElement = this.createAnnotationElement.bind(this);
    this.stylesheet = new Stylesheet;

    this.onLocalWindowScroll = this.onLocalWindowScroll.bind(this);
    this.onLocalWindowClick = this.onLocalWindowClick.bind(this);
    this.onAnnotationClick = this.onAnnotationClick.bind(this);
  }

  update({ textElement, response, config }) {
    this.updateTextElement(textElement);
    this.updateResponse(response);
    this.updateConfig(config);
  }

  updateTextElement(textElement) {
    this.textElement = textElement;
  }

  updateConfig(config) {
    this.config = config;
    this.updateStylesheet(config.annotationTypes);
  }

  updateResponse({ annotationTypes, annotations }) {
    this.updateResponseAnnotationTypes(annotationTypes);
    this.updateResponseAnnotations(annotations);
  }

  updateResponseAnnotationTypes(responseAnnotationTypes) {
    this.annotationTypes = responseAnnotationTypes;
    this.updateAnnotationTypeClasses(responseAnnotationTypes);
  }

  updateResponseAnnotations(responseAnnotations) {
    this.annotations = responseAnnotations;
  }

  updateStylesheet(annotationTypesConfig) {
    this.stylesheet.update(annotationTypesConfig);
    this.stylesheet.addToDocument(this.textElement.ownerDocument);
  }

  updateAnnotationTypeClasses(responseAnnotationTypes) {
    this.textElement.classList.add("kt-root");
    for (const annotationType of responseAnnotationTypes) {
      this.textElement.classList.toggle(Fragment.sanitizeName(annotationType.id), !annotationType.disabled);
    }
  }

  updateMenuElement(menuElement) {
    this.menuElement = menuElement;
  }

  setHandleMenuClose(handleMenuClose) {
    this.handleMenuClose = handleMenuClose;
  }

  setHandleMenuOpen(handleMenuOpen) {
    this.handleMenuOpen = handleMenuOpen;
  }

  onLocalWindowScroll() {
    this.handleMenuClose();
  }

  onLocalWindowClick(event) {
    // If the click is outside the menu, we hide the menu
    if(this.menuElement !== event.target && !this.menuElement.contains(event.target)) {
      this.handleMenuClose();
    }
  }

  onAnnotationClick(event) {
    const annotationElementWrapper = new AnnotationElementWrapper(event.currentTarget, this.annotations, this.annotationTypes, this.config.annotationTypes);

    if(annotationElementWrapper.annotation()) {
      this.handleMenuOpen(event, annotationElementWrapper);
    }
  }

  installMenuCloseEventHandlers() {
    let localWindow = this.textElement.ownerDocument.defaultView || window;
    let doLoop = true;
    while (doLoop) {
      // Handle all clicks in the window and iframes
      localWindow.addEventListener("click", this.onLocalWindowClick);
      // If any of the iframes that we are in is scrolled, we hide the menu
      localWindow.addEventListener("scroll", this.onLocalWindowScroll);

      if (localWindow === window) { doLoop = false; }
      else { localWindow = localWindow.parent; }
    }
  }

  createAnnotationElement(annotation) {
    const wrap = document.createElement("span");
    wrap.setAttribute("class", Fragment.sanitizeName(annotation.typeId) + " annotation");
    wrap.setAttribute("data-ktid", annotation.id);
    wrap.addEventListener("click", this.onAnnotationClick);
    return wrap;
  }

  findAnnotationElement(domNode) {
    if (domNode.parentElement.dataset["ktid"]) {
      return domNode.parentElement;
    } else {
      return null;
    }
  }

  updateAnnotationElement(annotation, wrap) {
    const ids = wrap.dataset["ktid"].split(" ");
    ids.push(annotation.id);
    wrap.dataset["ktid"] = ids.join(" ");

    wrap.classList.add(Fragment.sanitizeName(annotation.typeId));
  }

  replaceAnnotation(annotation, replacement = null) {
    const elements = Array.from(this.textElement.querySelectorAll(`span[data-ktid~=${annotation.id}]`));
    const annotationClassname = Fragment.sanitizeName(annotation.typeId);

    const ids = elements.flatMap((e) => e.dataset["ktid"].split(" ")).filter((id) => id !== annotation.id);
    const classes = elements.flatMap((e) => Array.from(e.classList.values())).filter((clazz) => clazz !== annotationClassname);

    const first = elements[0];
    elements.slice(1).forEach((e) => {
      e.parentNode.removeChild(e);
    });

    if(replacement != null) {
      first.textContent = replacement;
    }

    if(ids.length === 0) {
      removeAnnotationTag(first);
      this.textElement.normalize();
    } else {
      first.dataset["ktid"] = Array.from(new Set(ids)).join(" ");
      first.className = Array.from(new Set(classes)).join(" ");
    }
  }

  annotationsForCategoryCount(category) {
    const query = `span.${Fragment.sanitizeName(category)}`;
    const ktids = new Set(Array.from(this.textElement.querySelectorAll(query)).map((span) =>
      span.dataset.ktid
    ));
    return ktids.size;
  }

  clearAnnotations() {
    Array.from(this.textElement.querySelectorAll("span[data-ktid]")).forEach((element) => {
      removeAnnotationTag(element);
    });
    this.textElement.normalize();
  }

  annotator(annotationTypes) {
    return new DomAnnotator(this.textElement, { annotationElementCreator: this, annotationTypes });
  }

  copyTextToClipboard() {
    const copyHandler = (event) => {
      this.textElement.removeEventListener("copy", copyHandler);

      const textElementClone = this.textElement.cloneNode(true);
      Array.from(textElementClone.querySelectorAll("span[data-ktid]")).forEach((element) => {
        removeAnnotationTag(element);
      });

      event.preventDefault();
      event.clipboardData.setData("text/plain", textElementClone.innerText);
      event.clipboardData.setData("text/html", textElementClone.innerHTML);
    };
    this.textElement.addEventListener("copy", copyHandler);
    this.textElement.focus();
    this.textElement.ownerDocument.execCommand("copy");
    this.textElement.removeEventListener("copy", copyHandler);
  }
}

export default TextElementWrapper;
