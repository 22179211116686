import Transports from "kt_jsgem/lib/transports/transports";

class KTService {
  constructor(config, { protocol, transport } = {}) {
    this.config = config;
    this.protocol = protocol || require("kt_jsgem/lib/protocols/kt2").default;
    this.transport = transport || Transports;
  }

  analyze(plainText, options) {
    const xmlString = this.protocol.createXMLRequest(plainText, options);
    return this.transport.performRequest(this.config.analyzePath, xmlString, { user: options.user }).then((xml) => {
      const { categories, languageLevel, problemSizes, assignments } = this.protocol.parseXMLResponse(xml);
      return { annotations: categories, options: { languageLevel }, problemSizes: problemSizes, assignments };
    }
    , (error) => {
      throw error;
    });
  }

  addSuggestion(word, alternatives, options) {
    const xmlString = this.protocol.createAddSuggestionRequest(word, alternatives, options);
    return this.transport.performRequest(this.config.addSuggestionPath, xmlString, { user: options.user }).then((xml) => {
      return this.protocol.parseAddSuggestionResponse(xml);
    }
    , (error) => {
      throw error;
    });
  }

  legacyConfig(options) {
    const xmlString = this.protocol.createLegacyConfigRequest(options);
    return this.transport.performRequest(options.configPath, xmlString, { user: options.user }).then((xml) => {
      return this.protocol.parseLegacyConfigResponse(xml);
    }
    , (error) => {
      throw error;
    });
  }

  getConfig(options) {
    const xmlString = this.protocol.createConfigRequest(options);
    return this.transport.performRequest(options.configPath, xmlString, { user: options.user, method: "GET" }).then((xml) => {
      return this.protocol.parseConfigResponse(xml);
    }
    , (error) => {
      throw error;
    });
  }
}

export default KTService;
